<template>
  <div class="dp-taste-map-overview">
    <div class="dp-taste-map-overview-section">
      <h2 class="dp-taste-map-overview-title">味覚マップとは</h2>

      <p class="dp-taste-map-overview-paragraph">
        コーヒーの味わいは、抽出によって大きく変化します。同じカプセルでも、標準レシピとプロの抽出レシピでは味わいが大きく変わることも。
      </p>

      <p class="dp-taste-map-overview-paragraph">
        味覚マップでは、コーヒーの味傾向をマップで表現しています。お気に入りのレシピや味傾向を探求したり、普段とは違うテイストに挑戦したり。味わいの変化をわかりやすく表現しました。
      </p>

      <p class="dp-taste-map-overview-paragraph">
        お気に入りの味わいを見つけて楽しみましょう！
      </p>
    </div>

    <div class="dp-taste-map-overview-section">
      <h2 class="dp-taste-map-overview-title">味覚マップの見方</h2>

      <p class="dp-taste-map-overview-paragraph">
        マップの上ほど軽やかな味わい、下に行くほど濃厚な味わいになります。横の軸では、酸味と苦味の割合を表現しています。
      </p>

      <div class="dp-taste-map-overview-example">
        <img
          src="/assets/img/drip-pod/pic_taste_map_example01.png"
          alt="味覚マップの例" />
        <div class="dp-taste-map-overview-example__legend">
          <DpTasteMapLegend />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WORDS } from '@/constants/words';
import { defineComponent, onMounted, inject } from '@vue/composition-api';

export default defineComponent({
  components: {
    DpTasteMapLegend: () =>
      import('@/components/dripPod/core/TasteMapLegend.vue')
  },

  beforeRouteEnter(to, from, next) {
    next(() => {
      if (!from.path) return;

      window.localStorage.setItem('BackFromTasteMap', from.path);
    });
  },

  beforeRouteLeave(to, from, next) {
    next(() => {
      window.localStorage.removeItem('BackFromTasteMap');
    });
  },

  setup: () => {
    const setHeaderBackTo = inject('setHeaderBackTo');

    onMounted(async () => {
      const backToPath = window.localStorage.getItem('BackFromTasteMap');
      if (!backToPath) return;

      setHeaderBackTo(window.localStorage.getItem('BackFromTasteMap'));
    });

    return {
      WORDS
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-taste-map-overview {
  @include mixin.dpDefaultText;

  padding: 32px 16px;
  background-color: variables.$dpGreyF1;

  * {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.dp-taste-map-overview-section {
  margin-bottom: 32px;
}

.dp-taste-map-overview-paragraph {
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 1.6;
}

.dp-taste-map-overview-title {
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.3;
  text-align: center;
}

.dp-taste-map-overview-example {
  margin-bottom: 32px;
  text-align: center;

  img {
    display: block;
    margin: 0 auto 24px;
    padding: 0 16px;
    width: 100%;
    max-width: 654px;
    height: auto;
  }
}

.dp-taste-map-overview-note {
  font-size: 12px;
  line-height: 1.4;
  color: variables.$dpGrey66;

  &__legend {
    margin-bottom: 32px;
  }
}
</style>
