var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dp-taste-map-overview" }, [
    _vm._m(0),
    _c("div", { staticClass: "dp-taste-map-overview-section" }, [
      _c("h2", { staticClass: "dp-taste-map-overview-title" }, [
        _vm._v("味覚マップの見方"),
      ]),
      _c("p", { staticClass: "dp-taste-map-overview-paragraph" }, [
        _vm._v(
          " マップの上ほど軽やかな味わい、下に行くほど濃厚な味わいになります。横の軸では、酸味と苦味の割合を表現しています。 "
        ),
      ]),
      _c("div", { staticClass: "dp-taste-map-overview-example" }, [
        _c("img", {
          attrs: {
            src: "/assets/img/drip-pod/pic_taste_map_example01.png",
            alt: "味覚マップの例",
          },
        }),
        _c(
          "div",
          { staticClass: "dp-taste-map-overview-example__legend" },
          [_c("DpTasteMapLegend")],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dp-taste-map-overview-section" }, [
      _c("h2", { staticClass: "dp-taste-map-overview-title" }, [
        _vm._v("味覚マップとは"),
      ]),
      _c("p", { staticClass: "dp-taste-map-overview-paragraph" }, [
        _vm._v(
          " コーヒーの味わいは、抽出によって大きく変化します。同じカプセルでも、標準レシピとプロの抽出レシピでは味わいが大きく変わることも。 "
        ),
      ]),
      _c("p", { staticClass: "dp-taste-map-overview-paragraph" }, [
        _vm._v(
          " 味覚マップでは、コーヒーの味傾向をマップで表現しています。お気に入りのレシピや味傾向を探求したり、普段とは違うテイストに挑戦したり。味わいの変化をわかりやすく表現しました。 "
        ),
      ]),
      _c("p", { staticClass: "dp-taste-map-overview-paragraph" }, [
        _vm._v(" お気に入りの味わいを見つけて楽しみましょう！ "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }